<!--空调模块--季节信息设置页-->
<template>
  <div
    id="seasonInfoEditor"
    v-loading="loading"
    element-loading-text="加载中...."
    ref="seasonInfoEditor"
  >
    <div class="header">
      <my-headertitle :quit="true"
        >{{$t("controllseason.b1")}}
        <template v-slot:name>
          <a-icon
            type="pushpin"
            :style="{ 'margin-right': '5px', color: '#7682CE' }"
          /><span style="margin-right:20px">{{seasonCtrlName}}</span>
        </template>
      </my-headertitle>
    </div>    
    <div class="main">
        <my-tabletitle>{{$t("controllseason.b1")}}</my-tabletitle>
        <table class ="table">
          <tr class="row">
            <td>
              <span>{{$t("controllseason.b2")}}</span>
            </td>
            <td>
              <a-select class="select" :value="seasonInfo.summerstartmonth" @change="ssmChange"> 
                  <a-select-option v-for="item in months" :key="item" :value="item">
                    {{item}}
                  </a-select-option>
              </a-select> 
              <span>{{$t("controllseason.b3")}}</span>
              <a-select class="select" :value="seasonInfo.summerstartday" @change="ssdChange">
                  <a-select-option v-for="item in days" :key="item">
                    {{item}}
                  </a-select-option>                  
              </a-select> 
              <span>{{$t("controllseason.b4")}}  ~  </span>
              <a-select class="select" :value="seasonInfo.summerendmonth" @change="semChange">
                  <a-select-option v-for="item in months" :key="item">
                    {{item}}
                  </a-select-option>
              </a-select>
              <span>{{$t("controllseason.b3")}}</span>
              <a-select class="select" :value="seasonInfo.summerendday" @change="sedChange">                  
                  <a-select-option v-for="item in days" :key="item">
                    {{item}}
                  </a-select-option>                  
              </a-select> 
              <span>{{$t("controllseason.b4")}}</span>
            </td>
          </tr>
          <tr class="row">
            <td>
              <span>{{$t("controllseason.b5")}}</span>
            </td>
            <td>
              <a-select class="select" :value="seasonInfo.winterstartmonth" @change="wsmChange">
                  <a-select-option v-for="item in months" :key="item">
                    {{item}}
                  </a-select-option>
              </a-select> 
              <span>{{$t("controllseason.b3")}}</span>
              <a-select class="select" :value="seasonInfo.winterstartday" @change="wsdChange">
                  <a-select-option v-for="item in days" :key="item">
                    {{item}}
                  </a-select-option>                  
              </a-select> 
              <span >{{$t("controllseason.b4")}} ~  </span>
              <a-select class="select" :value="seasonInfo.winterendmonth" @change="wemChange">
                  <a-select-option v-for="item in months" :key="item">
                    {{item}}
                  </a-select-option>
              </a-select>
              <span >{{$t("controllseason.b3")}}</span>
              <a-select class="select" :value="seasonInfo.winterendday" @change="wedChange">                  
                  <a-select-option v-for="item in days" :key="item">
                    {{item}}
                  </a-select-option>                  
              </a-select> 
              <span >{{$t("controllseason.b4")}}</span>
            </td>
          </tr>
          <tr class="row">
            <td>
              <span>{{$t("controllseason.b6")}}</span>
            </td>
            <td>
              <span>{{$t("controllseason.b7")}}</span>
            </td>
          </tr>
          <tr class="row">
            <td>
              <span>{{$t("controllseason.b8")}}</span>
            </td>
            <td>
              <span>{{$t("controllseason.b9")}}</span>
              <a-select class="selectRunMode" :value="seasonInfo.runmode" @change="rmChange">                  
                  <a-select-option v-for="item in this.runModes" :key="item">
                    {{item}}
                  </a-select-option>                  
              </a-select>
              <span>{{$t("controllseason.b10")}}</span>
            </td>
          </tr>
        </table>              
    </div>
    <div class="footer">
      <a-button type="primary"
      style="width: 120px"
      @click="update"
      :loading="buttonloading"
      >{{$t("controllseason.b11")}}</a-button>
    </div>    
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { getAirConditionSeasonInfo,updateAirConditionSeasonInfo} from "../../api/device";
import Template from "../doorDetails/components/template.vue";

export default {
  name: "seasonInfoEditor",
  data() {
    return {
      buttonloading: false,
      seasonCtrlName: this.$route.query.nodeName,
      visible: false,
      months:[1,2,3,4,5,6,7,8,9,10,11,12],
      days:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
      runModes:[
        this.$t("controllseason.b12"),
        this.$t("controllseason.b13"),
        this.$t("controllseason.b14"),
        this.$t("controllseason.b15"),
      ],    
      test:"",  
      seasonInfo: {},          
    };
  },
  mounted() {
    // console.log("seasonInfoEditor runModes:",this.runModes);
    this.getData();    
  },

  computed: {      
    loading: function () {
      if (this.seasonInfo == null) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {    
    seasonInfo (val, oldVal) {
      if (val !== oldVal) {
        
      }
      this.test = this.seasonInfo.summerstartmonth;
      console.log("seasonInfoEditor SeasonInfo:",this.test);
    }
  },
  
  methods: {
    ssmChange(value){
      // console.log("seasonInfoEditor ssmChange:",this.seasonInfo.summerstartmonth);
      this.seasonInfo.summerstartmonth=value;
      // console.log("seasonInfoEditor ssmChange:",this.seasonInfo.summerstartmonth);
    },
    ssdChange(value){
      this.seasonInfo.summerstartday=value;      
    },
    semChange(value){
      this.seasonInfo.summerendmonth=value;
    },
    sedChange(value){
      this.seasonInfo.summerendday=value;
    },
    wsmChange(value){
      this.seasonInfo.winterstartmonth=value;
    },
    wsdChange(value){
      this.seasonInfo.winterstartday=value;
    },
    wemChange(value){
      this.seasonInfo.winterendmonth=value;
    },
    wedChange(value){
      this.seasonInfo.winterendday=value;
    },
    rmChange(value){
      // console.log("seasonInfoEditor rmChange:",this.seasonInfo.runmode);
      this.seasonInfo.runmode=value;
      // console.log("seasonInfoEditor rmChange:",this.seasonInfo.runmode);
    },

    //设置更新
    update() {
      let state = true;
      if (this.seasonInfo == null ){
        state = false;
      }      
      if (state) {
        this.buttonloading = true; 
        console.log("updateAirConditionSeasonInfo par:",this.seasonInfo);     
        updateAirConditionSeasonInfo(this.seasonInfo)
          .then((res) => {
            this.buttonloading = false;
            console.log(res);
            if (res.errorCode == "00") {
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.buttonloading = false;
            console.log(err);
          });
      }
    },
    // 获取当前已注册的认证终端数据
    getData() {
      let par = {
        seasonCtrlId: this.$route.query.nodeId,
      };
      getAirConditionSeasonInfo(par)
        .then((res) => {
          let { data } = res;
          this.seasonInfo = data;  
          console.log("seasonInfoEditor SeasonInfo:",this.seasonInfo);                         
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle, 
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#seasonInfoEditor {
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 10px 20px 0px 20px;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 10px 20px;
  overflow: auto;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.table{
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.row {
  display: flex;
  justify-content: space-between; 
  padding: 15px 10px; 
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.row span {
  /* margin-left: 15px; */
  font-size: 13px;
  color: #898989;
  white-space: nowrap;
}
.row td{
  margin-left: 15px;
  margin-right:50px;
}
.select{
  /* margin-right:16px; */
  width: 60px;
  margin-left: 10px;
  margin-right:10px;
} 
.selectRunMode{
  width: 110px;
  margin-left: 10px;
  margin-right:10px;
} 

</style>